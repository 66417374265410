import { DomHelper } from '../utils/dom-helper'
import { UrlHelper } from '../utils/url-helper'
import { FilterBarFormHandler } from './filter-bar-form-handler'

/**
 * Componente responsável por barra de filtro simples.
 */

export class FilterBar {

  /* consts */

  static INPUT_CHANGE_SELECTORS = 'select, input[type=checkbox], [data-filter-bar-field]'

  constructor(aFilterBarContainer) {
    /* globals */

    const self = this

    this._domFilterBarContainer = aFilterBarContainer
    this._domHelper = new DomHelper(this._domFilterBarContainer)
    this._formHandler = new FilterBarFormHandler(this._domFilterBarContainer, this)
    this._urlHelper = new UrlHelper()
    this._initEventHandlers()

    aFilterBarContainer._filterBar = this
  }

  /* public API */

  clearFilter() {
    this._formHandler.clearFilter()
  }

  getFilterBarId() {
    this._getFilterBarId()
  }

  submitForm() {
    this._formHandler.submitForm()
  }

  updateUrl() {
    this._formHandler.updateUrl()
  }

  getFormParameters() {
    return this._formHandler.getFormParameters()
  }

  /* event handlers */

  // history.back...

  _initEventHandlers() {

    let self = this

    window.addEventListener('popstate', function(aEvent) {
      // aEvent.target.location.search = parametros da url antiga que está sendo 'popped'
      // ex: ?cod_gestora=+&data_assinatura=&data_publicacao_portal=&decricao_modalidade=+&page=17&search=&sort_column=integration_supports_creditors.nome&sort_direction=desc&status=+&tipo_objeto=+

      var stateData = aEvent.state,
          filterBarId = (stateData && stateData['filter_bar_id'])

      if (filterBarId === self._getFilterBarId()) {
        var params = self._urlHelper.getUrlParams(aEvent.target.location.search)

        _restoreFormParameters(params)
      }

    }, true)

    // usuário clicou no botão de limpar o filtro...

    this._domFilterBarContainer.addEventListener('click', (aEvent) => {

      if (aEvent.target.matches('button[type=reset]')) {
        self._formHandler.clearFilter()
      }
    })

    // usuário mudou alguma opção de filtro em select...

    this._domFilterBarContainer.addEventListener('change', (aEvent) => {
      if (aEvent.target.matches(FilterBar.INPUT_CHANGE_SELECTORS)) {
        if (self._considerInputChanged(aEvent.target)) {
          self._formHandler.submitForm()
        }
      }
    })

    // usuário pressionou enter no campo de busca...

    this._domFilterBarContainer.addEventListener('keyup', (aEvent) => {
      if (aEvent.target.matches('input[type=text]')) {
        self._formHandler.handleKeyUp(aEvent)
      }
    })

  }

  /* privates */

  _considerInputChanged(aInput) {
    // temos que ignorar os changes enquanto estiver limpando filtros do select2
    // pois é necessário dar um trigger nessa merda de componente.
    if (this._formHandler.isClearingFilters()) {
      return false
    }

    return (aInput.getAttribute('data-filter-bar-ignore') !== 'true')
  }

  _getFilterBarId() {
    return this._domFilterBarContainer.getAttribute('data-filter-bar')
  }
}
