require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// Usar a função import() separa os assets em chuncks assíncronos
import('popper.js')
import('bootstrap')

import('components')
import('controllers')

import './modules/index'
import '../stylesheets/base.scss'

// importante para conectar com turbo-ios
import * as Turbo from "@hotwired/turbo"
window.Turbo = Turbo

import delegate from 'delegate-it'
window.delegate = delegate

import select from 'select-dom'
window.select = select

import Rails from '@rails/ujs'
window.Rails = Rails

import { Tab } from 'bootstrap'
window.Tab = Tab
